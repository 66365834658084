/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';

const mock = [
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/complain1_new.jpeg',
    description:
      'We will go through a process to identify the exact issues you as the client have raised. We will work with you to identify why you believe it is an issue and the impact of the issue on yourself or your business. From there, we will be able to identify what outcome(s) you are seeking. An attempt to find an outcome that is agreeable to all parties concerned will then be made.',
    desc: 'Should your complaint be against the insurance provider, we will instruct you of your right to submit a complaint directly to them and provide the relevant contact information in order for you to do so. We will further act as the middleman to try to come to an agreeable outcome for all parties concerned.',
    title: 'Step 1: Identify the Issues',
    tags: [],
    author: {
      name: 'Clara Bertoletti',
      avatar: 'https://assets.maccarianagency.com/avatars/img2.jpg',
    },
    date: '04 Aug',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/complaint2_new.jpeg',
    description:
      "If we can't resolve the issue together, you’ll be referred to a senior manager who will aim to investigate and resolve your complaint within two working weeks.",
    desc: '',
    title: 'Step 2: Senior Manager Involvement',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
  {
    image:
      'https://fg-life-assets.s3.ap-southeast-2.amazonaws.com/complaint3_new.jpeg',
    description:
      'If after working with a senior manager we still haven’t been able to resolve the issue, you will be directed to escalate your case to our independent dispute resolution provider.',
    desc: '',
    title: 'Step 3: Independent Dispute Resolution',
    tags: [],
    author: {
      name: 'Jhon Anderson',
      avatar: 'https://assets.maccarianagency.com/avatars/img5.jpg',
    },
    date: '12 Sep',
  },
];

const PopularNews = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4} bgcolor={theme.palette.alternate.main}>
        <Typography
          variant="h4"
          data-aos={'fade-in'}
          align={'center'}
          gutterBottom
          sx={{ fontWeight: 700 }}
        >
          Complaint Process
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          We’re committed to delivering services that meet your expectations. We
          acknowledge that all clients have the right to express dissatisfaction
          and to have their complaints heard in a fair and prompt manner.
          Complaints, though undesirable, also offer us an opportunity to
          improve our operations, build a stronger relationship with you, and
          show you that as a client your opinions and needs are important to us.
        </Typography>
        <Typography
          variant="p"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 400,
          }}
        >
          <br />
          <br />
          If however, we have let you down, we want to hear about it. Complaints
          may be received verbally either in person or by telephone, or in
          written and email form.
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography
          variant="h6"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 800,
          }}
        >
          What Constitutes a Complaint
        </Typography>
        <Typography
          variant="p"
          //align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{ fontWeight: 400 }}
          // color={'text.secondary'}
        >
          A complaint is an expression of dissatisfaction communicated by a
          client who has received financial advice about a product or service
          through us and there is a request to remedy the situation.
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography
          variant="h6"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 800,
          }}
        >
          What to Expect When You Make a Complaint
        </Typography>
        <Typography
          variant="p"
          //align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{ fontWeight: 400 }}
          // color={'text.secondary'}
        >
          We are committed to dealing with complaints efficiently and
          positively. When a complaint is received, we will listen to you with
          patience and understanding and promise to be courteous. We will work
          with you to obtain a satisfactory resolution, keeping your best
          interest in mind.
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography
          variant="h6"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 800,
          }}
        >
          How to Make a Complaint
        </Typography>
        <Typography
          variant="p"
          //align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{ fontWeight: 400 }}
          // color={'text.secondary'}
        >
          Each complaint, regardless of the way it was received, will be
          recorded and retained in our Complaints Register. You can let us know
          about your concern in any of the following ways:
          <br />A quick chat to your advisor or one of our staff either
          telephonically or in person. This is often enough to sort out your
          concern.
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography variant="h6" sx={{ fontWeight: 800 }}>
          Call us on: 0800 344 700
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography variant="h6" sx={{ fontWeight: 800 }}>
          Visit us at: 430 Ulster Street Beerescourt Hamilton 3200
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography variant="p" sx={{ fontWeight: 400 }}>
          Alternatively, You can send an email or letter. A representative will
          endeavour to reach out to you within 24 hours of receiving a written
          complaint (or within 48 hours if received outside of working hours on
          a weekend).
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography variant="h6" sx={{ fontWeight: 800 }}>
          Send an email to:complaints@fglife.co.nz
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography variant="h6" sx={{ fontWeight: 800 }}>
          Send a letter to: PO Box 21017 Rototuna Hamilton 3256
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              borderRadius={0}
              boxShadow={0}
              display={'flex'}
              flexDirection={{
                xs: 'column',
                md: i % 2 === 0 ? 'row-reverse' : 'row',
              }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Box
                sx={{
                  width: { xs: 1, md: '50%' },
                }}
              >
                <Box
                  component={'img'}
                  loading="lazy"
                  height={1}
                  width={1}
                  src={item.image}
                  alt="..."
                  sx={{
                    objectFit: 'cover',
                    maxHeight: 360,
                    borderRadius: 2,
                    filter:
                      theme.palette.mode === 'dark'
                        ? 'brightness(0.7)'
                        : 'none',
                  }}
                />
              </Box>
              <CardContent
                sx={{
                  paddingX: { xs: 1, sm: 2, md: 4 },
                  paddingY: { xs: 2, sm: 4 },
                  width: { xs: 1, md: '50%' },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Box>
                  {item.tags.map((item) => (
                    <Chip
                      key={item}
                      label={item}
                      component="a"
                      href=""
                      clickable
                      size={'small'}
                      color={'primary'}
                      sx={{ marginBottom: 1, marginRight: 1 }}
                    />
                  ))}
                </Box>
                <Typography
                  variant={'h6'}
                  fontWeight={700}
                  sx={{ textTransform: 'uppercase' }}
                >
                  {item.title}
                </Typography>
                {/* <Box marginY={1}>
                  <Typography
                    variant={'caption'}
                    color={'text.secondary'}
                    component={'i'}
                  >
                    {item.author.name} - {item.date}
                  </Typography>
                </Box> */}
                <Typography color="text.secondary">
                  {item.description}
                  <br />
                  <br />
                  {item.desc}
                </Typography>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box margin={3}>
        <Typography
          variant="h6"
          align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 800,
          }}
        ></Typography>
        <Typography
          variant="p"
          //align={'left'}
          // data-aos={'fade-up'}
          gutterBottom
          sx={{ fontWeight: 400 }}
          // color={'text.secondary'}
        >
          If we cannot resolve the issue to your satisfaction, we will refer it
          to our external independent dispute resolution service, the Financial
          Dispute Resolution Service (FDRS). The FDRS does not charge a fee to
          investigate or resolve a complaint. You can contact FDRS by:
        </Typography>
      </Box>

      <Box margin={3}>
        <Typography variant="h6" sx={{ fontWeight: 800 }}>
          FDRS - Financial Dispute Resolution Service
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography variant="h6" sx={{ fontWeight: 800 }}>
          POST: Freepost 231075, PO Box 2272, Wellington 6140
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography variant="h6" sx={{ fontWeight: 800 }}>
          Call: 0508 337 337
        </Typography>
      </Box>
      <Box margin={3}>
        <Typography variant="h6" sx={{ fontWeight: 800 }}>
          Email:enquiries@fdrs.org.nz
        </Typography>
      </Box>
    </Box>
  );
};

export default PopularNews;
